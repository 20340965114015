<template>
  <div>
    <el-form ref="form" :model="formData" label-width="80px" >
      <el-form-item label="名称">
        <el-input v-model="formData.uname"></el-input>
      </el-form-item>
      <el-form-item label="平台" style="text-align: left">
        <el-select v-model="formData.platform_id" placeholder="请选择">
          <el-option
              v-for="item in formData.platformList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="平台标识">
        <el-input  v-model="formData.password" ></el-input>
      </el-form-item>
      <el-form-item label="标签" style="text-align: left">
        <el-checkbox-group v-model="formData.checkLabelList">
          <el-checkbox v-for="(item, key) in formData.labelList" :key="key" :label="item"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="轮播图" style="text-align: left">
        <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="上传文件" style="text-align: left">
        <el-upload
            class="upload-demo"
            ref="upload"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="formData.fileList"
            :auto-upload="false">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </el-form-item>
      <el-form-item style="text-align: left">
        <el-button type="primary" @click="onSubmit()" >确认添加</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>

  export default {
    name: 'VideoAdd',
    components: {
    },
    data() {
      return {
        formData: {
          uname: '',
          password: '',
          platformList: [{
            value: '1',
            label: 'HXC'
          }],
          platform_id: '1',
          labelList: ['A','B','C','D','E','F','G'],
          checkLabelList: [],
          fileList: []
        },

      };
    },
    methods: {
      submitUpload() {
        this.$refs.upload.submit();
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
    }
  }
</script>

<style scoped>

</style>
