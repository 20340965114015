<template>
  <div>
      视频列表
  </div>
</template>

<script>
  export default {
    name: 'VideoList',
    components: {
    },
  }
</script>

<style scoped>

</style>
